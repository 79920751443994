<script> 
import { mapState } from 'vuex';  
import PQuire from '@/components/reservationInquire/pc_quire/pc_quire'
import HQuire from '@/components/reservationInquire/h5_quire/h5_quire'

export default {
	components:{},
	name: 'reservationInquire',
	data() {
		return { 
		};
	},
	components: {
		[PQuire.name]: PQuire,
		[HQuire.name]: HQuire
	},
	watch: {
		
	},
	render(createElement) {
	  if (this.isPc) {
	    // return createElement('pc_quire') 
		this.$router.push({ name: 'testLogIn', params: { type: 'pre' } })
	  }
	  return createElement('h5_quire')
	},
	created() {
		 
	},
	mounted() {},
	computed: {
		isPc() {
		  return this.$store.state.isPc;
		},
	},
	computed: {
	  ...mapState(['isPc'])
	},
};
</script>

<style lang="scss" scoped="scoped"> 
</style>
