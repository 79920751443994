<template>
	<div class="wrapper">无 123123</div>
</template>

<script>
export default {
	components: {},
	name: 'pc_quire',
	data() {
		return {};
	},
	watch: {},
	components: {},
	created() {},
	mounted() {},
	computed: {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

</style>
